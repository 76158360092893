import { extendTheme } from '@chakra-ui/react'

export const vyColorScheme = extendTheme({
  colors: {
    brand: {
      lightGreen20: '#CCEAE4',
      lightGreen30: '#B2DFD7',
      lightGreen40: '#99D5CA',
      darkGrey: '#2b2b2b',
      white: '#FFFFFF',
      actionGreen: '#00866E',
    },
  },
  components: {
    Button: {
      baseStyle: {
        paddingLeft: 30,
        paddingRight: 30,
      },
      variants: {
        'primary-button': () => ({
          color: 'white',
          bg: 'teal',
        }),
        'friendly-button': (props) => ({
          color: props.colorMode === 'light' ? 'brand.darkGrey' : 'white',
          bg: props.colorMode === 'light' ? 'brand.lightGreen20' : 'gray.700',
        }),
      },
    },
  },
})
