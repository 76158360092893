import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Box,
  Button,
} from '@chakra-ui/react'
import React from 'react'

type DeleteAlertInput = {
  isOpen: boolean
  onClose: () => void
  cancelRef: React.MutableRefObject<null>
  header: string
  deletedObjectTitle: string
  onClick: () => void
}

export function DeleteAlert(props: DeleteAlertInput) {
  const { isOpen, onClose, cancelRef, header, deletedObjectTitle, onClick } =
    props

  return (
    <AlertDialog
      isOpen={isOpen}
      leastDestructiveRef={cancelRef}
      onClose={onClose}
      isCentered
    >
      <AlertDialogOverlay>
        <AlertDialogContent>
          <AlertDialogHeader fontSize="lg" fontWeight="bold">
            {header}
          </AlertDialogHeader>
          <AlertDialogBody>
            Er du sikker på at du vil slette
            <Box fontWeight="bold" as="span">
              {` ${deletedObjectTitle}`}
            </Box>
            ?
          </AlertDialogBody>
          <AlertDialogFooter>
            <Button ref={cancelRef} onClick={onClose}>
              Avbryt
            </Button>
            <Button colorScheme="red" onClick={onClick} ml={3}>
              Slett
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  )
}
