import { AttachmentIcon, SmallCloseIcon } from '@chakra-ui/icons'
import {
  Box,
  Center,
  Flex,
  Text,
  ListItem,
  UnorderedList,
  useColorModeValue,
} from '@chakra-ui/react'
import React from 'react'

type FileInputListProps = {
  files: File[]
  setFiles: (
    newFiles: File[],
  ) => void | React.Dispatch<React.SetStateAction<File[]>>
}

export function FileInputList(props: FileInputListProps) {
  const { files, setFiles } = props

  return (
    <UnorderedList listStyleType="none" margin="0">
      {files &&
        files.map((file) => (
          <ListItem
            key={file.name}
            borderRadius="8px"
            backgroundColor={useColorModeValue(
              'brand.lightGreen20',
              'gray.800',
            )}
            marginY="2"
            padding="2"
          >
            <Flex justifyContent="space-between">
              <Box>
                <AttachmentIcon width="3" height="3" marginRight="2" />
                <Text as="span" fontSize="14px">
                  {file.name}
                </Text>
              </Box>
              <Center>
                <SmallCloseIcon
                  _hover={{ cursor: 'pointer', color: 'red.600' }}
                  onClick={() => {
                    const newFiles = files.filter(
                      (currentFile) => currentFile !== file,
                    )
                    setFiles([...newFiles])
                  }}
                />
              </Center>
            </Flex>
          </ListItem>
        ))}
    </UnorderedList>
  )
}
