import React from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'
import { LandingPage } from 'pages/LandingPage'
import { Audio } from 'pages/Audio'
import { PDFPage } from 'pages/PDFPage'
import { Header } from 'components/header'
import { UrlPath } from 'utils/paths'
import { QueryClient, QueryClientProvider } from 'react-query'
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
} from '@azure/msal-react'

export function App() {
  return (
    <>
      <UnauthenticatedTemplate>
        <LandingPage />
      </UnauthenticatedTemplate>
      <AuthenticatedTemplate>
        <QueryClientProvider client={new QueryClient()}>
          <Header />
          <Switch>
            <Route path={UrlPath.AUDIO}>
              <Audio />
            </Route>
            <Route path={UrlPath.PDF_FILES}>
              <PDFPage />
            </Route>
            <Route path="/">
              <Redirect to={UrlPath.AUDIO} />
            </Route>
          </Switch>
        </QueryClientProvider>
      </AuthenticatedTemplate>
    </>
  )
}
