import { removeSuffix } from 'utils/removeSuffix'

const trailingBackslashRegex = /\/$/

const baseUrl = removeSuffix(
  trailingBackslashRegex,
  process.env.REACT_APP_ENTERTAINMENT_API_BASE_URL,
)

export const endpoints = {
  pdfsUrl: () => `${baseUrl}/pdf-files`,
  editPdfsUrl: (id: number) => `${baseUrl}/pdf-files/${id}`,
  getIssueUrl: (id: number) => `${baseUrl}/issues/${id}`,

  issuersUrl: () => `${baseUrl}/issuers`,
  issuerUrl: (id: number) => `${baseUrl}/issuers/${id}`,

  audioUrl: () => `${baseUrl}/audio`,
  audioTracksUrl: (id: number) => `${baseUrl}/audio/${id}/tracks`,
  editAudioUrl: (id: number) => `${baseUrl}/audio/${id}`,
  editAudioTrack: (audioId: number, trackNumber: number) =>
    `${baseUrl}/audio/${audioId}/tracks/${trackNumber}`,
}
