import {
  Box,
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  Textarea,
  useToast,
} from '@chakra-ui/react'
import React, { useCallback, useEffect, useState } from 'react'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import { useAPI } from 'utils/useAPI'
import { endpoints } from 'utils/endpoints'
import { useMutation } from 'react-query'
import axios, { AxiosError, AxiosResponse, CancelTokenSource } from 'axios'
import { AudioResponse, AudioTableData, AudioUpdateRequest } from 'types/audio'
import { DatetimePicker } from 'components/datetime-picker/DatetimePicker'
import { format } from 'date-fns'
import { FileInput, FileInputList } from 'components/file-input'
import { FileRejection } from 'react-dropzone'
import { ProgressBar } from 'components/ProgressBar'
import { Language, useLanguage } from 'utils/language-util'

type FormInput = {
  isOpen: boolean
  onClose: () => void
  reloadAudio: () => void
  setUpdatedRow: (id: number) => void
} & (AudioCreateInput | AudioUpdateInput)

type FormValues = {
  audioId: number
  type: string
  title: string
  author: string
  description: string
  order: number
  availableFrom?: string
  availableUntil?: string
  coverPhotoFile?: File
  audioFile?: File
  language: Language
}

type AudioUpdateInput = {
  mode: 'update'
  audio: AudioTableData
}
type AudioCreateInput = {
  mode: 'create'
  audio?: undefined
}

export function AudioForm(props: FormInput) {
  const { isOpen, reloadAudio, onClose, setUpdatedRow, mode, audio } = props
  const [uploadProgress, setUploadProgress] = useState(0)
  const [cancelToken, setCancelToken] = useState<
    CancelTokenSource | undefined
  >()

  const [language] = useLanguage()

  useEffect(() => {
    if (isOpen) {
      setUploadProgress(0)
      setCancelToken(axios.CancelToken.source())
    }
  }, [isOpen])

  const {
    handleSubmit,
    control,
    watch,
    register,
    formState: { errors, isSubmitting },
    reset,
    setValue,
    clearErrors,
    setError,
    getValues,
  } = useForm<FormValues>()

  const resetFormAndClose = async () => {
    reset({
      audioId: undefined,
      type: undefined,
      title: undefined,
      author: undefined,
      description: undefined,
      order: undefined,
      availableFrom: undefined,
      availableUntil: undefined,
      coverPhotoFile: undefined,
      audioFile: undefined,
      language: undefined,
    })
    cancelToken?.cancel()
    onClose()
  }

  const api = useAPI()
  const toast = useToast()
  const initialRef = React.useRef(null)
  const finalRef = React.useRef(null)

  useEffect(() => {
    if (isOpen && audio) {
      if (audio.type) {
        setValue('type', audio.type)
      } else {
        setValue('type', 'Audiobook')
      }
      if (audio.title) {
        setValue('title', audio.title)
      }
      if (audio.author) {
        setValue('author', audio.author)
      }
      if (audio.description) {
        setValue('description', audio.description)
      }
      if (audio.order) {
        setValue('order', audio.order)
      }
      if (audio.availableFrom) {
        setValue(
          'availableFrom',
          format(audio.availableFrom, "yyyy-MM-dd'T'HH:mm"),
        )
      }
      if (audio.availableUntil) {
        setValue(
          'availableUntil',
          format(audio.availableUntil, "yyyy-MM-dd'T'HH:mm"),
        )
      }
      if (audio.language) {
        setValue('language', audio.language)
      }
    } else if (isOpen) {
      setValue('type', 'Audiobook')
    }
  }, [isOpen, audio])

  const { mutate: sendRequest, isLoading } = useMutation<
    AxiosResponse<AudioResponse>,
    AxiosError<string>,
    AudioUpdateRequest
  >((inputData) => {
    if (mode === 'update') {
      return api.put(endpoints.editAudioUrl(audio!.id), inputData)
    } else {
      return api.post(endpoints.audioUrl(), inputData, {
        headers: { 'Content-Type': 'multipart/form-data' },
        onUploadProgress: (progress) => {
          setUploadProgress(progress.loaded / (progress.total ?? 1))
        },
        cancelToken: cancelToken!.token,
      })
    }
  })

  function getAudioRequest(data: FormValues) {
    if (mode === 'create') {
      const formData = new FormData()
      formData.append('type', data.type)
      formData.append('title', data.title)
      formData.append('author', data.author)
      formData.append('description', data.description)
      formData.append('order', `${data.order}`)

      if (data.audioFile) {
        formData.append('audioZip', data.audioFile)
      }

      formData.append('coverPhoto', data.coverPhotoFile!)
      formData.append('language', data.language)

      if (data.availableFrom) {
        formData.append(
          'availableFrom',
          new Date(data.availableFrom).toISOString(),
        )
      }
      if (data.availableUntil) {
        formData.append('availableFrom', data.availableUntil)
      }

      return formData
    } else {
      return {
        type: data.type,
        title: data.title,
        author: data.author,
        description: data.description,
        order: data.order,
        availableFrom: data.availableFrom
          ? new Date(data.availableFrom).toISOString()
          : null,
        availableUntil: data.availableUntil
          ? new Date(data.availableUntil).toISOString()
          : null,
        audioZip: data.audioFile ? data.audioFile : null,
        coverPhoto: data.coverPhotoFile || null,
        language: data.language,
      }
    }
  }

  function getToastTitle() {
    if (mode === 'create') {
      return 'opprettet'
    } else {
      return 'oppdatert'
    }
  }

  const onSubmit: SubmitHandler<FormValues> = async (data) => {
    if (mode === 'create') {
      if (!data.coverPhotoFile) {
        toast({
          title: `Du må laste opp cover-bilde.`,
          status: 'error',
          duration: null,
          isClosable: true,
          position: 'bottom-right',
        })
        return
      }
    }

    sendRequest(getAudioRequest(data), {
      onSuccess: (response) => {
        reloadAudio()
        resetFormAndClose()
        setUpdatedRow(response.data.id)

        toast({
          title: `Lydbok ${getToastTitle()}.`,
          status: 'success',
          duration: 3000,
          isClosable: true,
          position: 'bottom-right',
        })
      },

      onError: () => {
        toast({
          title: `Lydbok ble ikke ${getToastTitle()}.`,
          status: 'error',
          duration: 4000,
          isClosable: true,
          position: 'bottom-right',
        })
      },
    })
  }

  const onDropCallback = useCallback(
    (acceptedFiles: File[], fileRejections: FileRejection[]) => {
      clearErrors('audioFile')

      if (fileRejections.length > 0) {
        setError('audioFile', {
          type: 'manual',
          message: 'Kun én fil kan lastes opp, av typen .zip',
        })
      }

      setValue('audioFile', acceptedFiles ? acceptedFiles[0] : undefined)
    },
    [getValues('audioFile')],
  )

  const onCoverPhotoDropCallback = useCallback(
    (acceptedFiles: File[], fileRejections: FileRejection[]) => {
      clearErrors('coverPhotoFile')

      if (fileRejections.length > 0) {
        setError('coverPhotoFile', {
          type: 'manual',
          message: 'Kun én fil kan lastes opp, av typen .png eller .jpeg',
        })
      }

      setValue('coverPhotoFile', acceptedFiles ? acceptedFiles[0] : undefined)
    },
    [getValues('coverPhotoFile')],
  )

  return (
    <Box ref={finalRef}>
      <Modal
        initialFocusRef={initialRef}
        finalFocusRef={finalRef}
        isOpen={isOpen}
        onClose={resetFormAndClose}
      >
        <ModalOverlay />
        <ModalContent minWidth="700">
          <ModalHeader>
            {mode === 'create' ? 'Last opp' : 'Rediger'}
          </ModalHeader>
          <ModalCloseButton />
          <form onSubmit={handleSubmit(onSubmit)}>
            <ModalBody>
              <>
                <FormControl isRequired marginBottom="5">
                  <FormLabel htmlFor="language">Land</FormLabel>
                  <Select
                    id="language"
                    {...register('language')}
                    defaultValue={language}
                  >
                    <option key="no" value="no">
                      🇳🇴
                    </option>
                    <option key="se" value="se">
                      🇸🇪
                    </option>
                  </Select>
                </FormControl>
                <FormLabel htmlFor="type">Type</FormLabel>
                <Select id="type" {...register('type')}>
                  <option key="Audiobook" value="Audiobook">
                    Lydbok
                  </option>
                  <option key="Podcast" value="Podcast">
                    Podcast
                  </option>
                </Select>

                <FormControl
                  isRequired
                  isInvalid={errors.title !== undefined}
                  marginTop="15px"
                >
                  <FormLabel htmlFor="title">Tittel</FormLabel>
                  <Input
                    id="title"
                    placeholder="Skriv inn her"
                    defaultValue={audio?.title}
                    {...register('title', {
                      required: 'Dette feltet er påkrevd',
                      minLength: {
                        value: 2,
                        message: 'Minimumslengde er 2',
                      },
                    })}
                  />
                  <FormErrorMessage>
                    {errors.title && errors.title.message}
                  </FormErrorMessage>
                </FormControl>
                <FormControl
                  isInvalid={errors.author !== undefined}
                  marginTop="15px"
                  isRequired
                >
                  <FormLabel htmlFor="author">Forfatter</FormLabel>
                  <Input
                    id="author"
                    placeholder="Skriv inn her"
                    defaultValue={audio?.author}
                    {...register('author', {
                      required: 'Dette feltet er påkrevd',
                      minLength: {
                        value: 2,
                        message: 'Minimumslengde er 2',
                      },
                    })}
                  />
                  <FormErrorMessage>
                    {errors.author && errors.author.message}
                  </FormErrorMessage>
                </FormControl>
                <FormControl
                  isInvalid={errors.description !== undefined}
                  marginTop="15px"
                  isRequired
                >
                  <FormLabel htmlFor="description">Beskrivelse</FormLabel>
                  <Textarea
                    id="description"
                    placeholder="Skriv inn her"
                    minHeight="150"
                    defaultValue={audio?.description}
                    {...register('description', {
                      required: 'Dette feltet er påkrevd',
                      minLength: {
                        value: 2,
                        message: 'Minimumslengde er 2',
                      },
                    })}
                  />
                  <FormErrorMessage>
                    {errors.description && errors.description.message}
                  </FormErrorMessage>
                </FormControl>
                <FormControl
                  isInvalid={errors.order !== undefined}
                  marginTop="15px"
                  isRequired
                >
                  <FormLabel htmlFor="order">Rekkefølge</FormLabel>
                  <Input
                    id="order"
                    type="number"
                    placeholder="Skriv inn her"
                    defaultValue={audio?.order}
                    {...register('order', {
                      required: 'Dette feltet er påkrevd',
                      minLength: {
                        value: 1,
                        message: 'Minimumslengde er 1',
                      },
                    })}
                  />
                  <FormErrorMessage>
                    {errors.order && errors.order.message}
                  </FormErrorMessage>
                </FormControl>
                <FormControl paddingTop="3" marginTop="15px">
                  <FormLabel htmlFor="availableFrom">
                    Tilgjengelig fra
                  </FormLabel>
                  <div style={{ display: 'flex', flexDirection: 'row' }}>
                    <Controller
                      control={control}
                      name="availableFrom"
                      render={({ field: { onChange, ref } }) => (
                        <DatetimePicker
                          value={watch('availableFrom')}
                          onChange={onChange}
                          ref={ref}
                        />
                      )}
                    />
                    <Button
                      style={{ marginLeft: 15 }}
                      onClick={() => {
                        const formatted = format(
                          new Date(),
                          "yyyy-MM-dd'T'HH:mm",
                        )

                        setValue('availableFrom', formatted)
                      }}
                    >
                      I dag
                    </Button>
                  </div>
                </FormControl>
                <FormControl paddingTop="3" marginTop="15px">
                  <FormLabel htmlFor="unpublishedAt">
                    Tilgjengelig til
                  </FormLabel>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                    }}
                  >
                    <Controller
                      control={control}
                      name="availableUntil"
                      render={({ field: { onChange, ref } }) => (
                        <DatetimePicker
                          value={watch('availableUntil')}
                          onChange={onChange}
                          ref={ref}
                        />
                      )}
                    />
                    <Button
                      style={{ marginLeft: 15 }}
                      onClick={() => {
                        const formatted = format(
                          new Date(),
                          "yyyy-MM-dd'T'HH:mm",
                        )

                        setValue('availableUntil', formatted)
                      }}
                    >
                      I dag
                    </Button>
                  </div>
                </FormControl>
                {mode === 'create' && (
                  <>
                    <FormControl
                      isRequired
                      isInvalid={errors.coverPhotoFile !== undefined}
                      paddingTop="5"
                    >
                      <FormLabel htmlFor="coverPhotoFile">Bilde</FormLabel>
                      <FileInput
                        id="coverPhotoFile"
                        onDrop={onCoverPhotoDropCallback}
                        maxFiles={1}
                        accept={[
                          '.png',
                          '.PNG',
                          '.jpg',
                          '.JPG',
                          '.jpeg',
                          '.JPEG',
                        ]}
                      />
                      <FileInputList
                        files={
                          watch('coverPhotoFile')
                            ? ([getValues('coverPhotoFile')] as File[])
                            : ([] as File[])
                        }
                        setFiles={(newFiles) => {
                          setValue(
                            'coverPhotoFile',
                            newFiles ? newFiles[0] : undefined,
                          )
                        }}
                      />
                      <FormErrorMessage>
                        {errors.coverPhotoFile && errors.coverPhotoFile.message}
                      </FormErrorMessage>
                    </FormControl>
                    <FormControl
                      isInvalid={errors.audioFile !== undefined}
                      paddingTop="5"
                    >
                      <FormLabel htmlFor="audioFile">Fil</FormLabel>
                      <FileInput
                        id="audioFile"
                        onDrop={onDropCallback}
                        maxFiles={1}
                        accept=".zip"
                      />
                      <FileInputList
                        files={
                          watch('audioFile')
                            ? ([getValues('audioFile')] as File[])
                            : ([] as File[])
                        }
                        setFiles={(newFiles) => {
                          setValue(
                            'audioFile',
                            newFiles ? newFiles[0] : undefined,
                          )
                        }}
                      />
                      <FormErrorMessage>
                        {errors.audioFile && errors.audioFile.message}
                      </FormErrorMessage>
                    </FormControl>
                  </>
                )}
              </>
            </ModalBody>
            <ProgressBar progress={uploadProgress} isLoading={isLoading} />

            <ModalFooter style={{ marginTop: '20px' }}>
              <Button
                variant="primary-button"
                mr={3}
                isLoading={isSubmitting || isLoading}
                type="submit"
              >
                {mode === 'create' ? 'Opprett' : 'Oppdater'}
              </Button>
              <Button onClick={resetFormAndClose}>Avbryt</Button>
            </ModalFooter>
          </form>
        </ModalContent>
      </Modal>
    </Box>
  )
}
