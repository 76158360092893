import { Language } from 'utils/language-util'

export enum AudioType {
  AUDIOBOOK = 'Audiobook',
  PODCAST = 'Podcast',
}

export type AudioResponse = {
  id: number
  title: string
  author: string
  chapters: string
  order?: number
  type: AudioType
  size: number
  description: string
  availableFrom: string | null
  availableUntil: string | null
  createdAt: string | null
  updatedAt: string | null
  coverPhotoUrl: string | null
  language: Language
}

export type AudioTableData = {
  coverPhotoUrl: string | null
  id: number
  title: string
  author: string
  chapters: string
  order?: number
  type: AudioType
  size: number
  description: string
  availableFrom: Date | null
  availableUntil: Date | null
  createdAt: Date | null
  updatedAt: Date | null
  currentlyAvailable: boolean
  language: Language
}

export type Track = {
  audioId: number
  trackNumber: number
  title: string
  url: string | null
  description: string | null
}

export type AudioUploadRequest = FormData

export type AudioUpdateRequest =
  | {
      type: string
      title: string
      author: string
      description: string
      order: number
      availableFrom: string | null
      availableUntil: string | null
      audioZip: File | null
      coverPhoto: File | null
      language: Language
    }
  | FormData

export type AddAudioTrackRequest = FormData

export type EditAudioTrackRequest = FormData
