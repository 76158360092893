import React, { useState, useEffect } from 'react'
import { Box, Button, Flex, useDisclosure } from '@chakra-ui/react'
import { AudioTable } from 'components/table/AudioTable'
import { TabSelector } from 'components/TabSelector'
import { AudioResponse } from 'types/audio'
import { endpoints } from 'utils/endpoints'
import { useAPI } from 'utils/useAPI'
import { AudioForm } from 'components/AudioForm'
import { AddIcon } from '@chakra-ui/icons'
import { getCurrentLanguge } from 'utils/language-util'

enum TabHeader {
  AUDIOBOOKS = 'Lydbøker',
  PODCASTS = 'Podcaster',
}

function tabHeaderToAudioType(tabHeader: TabHeader): string {
  switch (tabHeader) {
    case TabHeader.AUDIOBOOKS:
      return 'Audiobook'
    case TabHeader.PODCASTS:
      return 'Podcast'
    default:
      return ''
  }
}

export function Audio() {
  const [selectedTab, setSelectedTab] = useState<TabHeader>(
    TabHeader.AUDIOBOOKS,
  )
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [audioResponse, setAudioresponse] = useState<AudioResponse[]>([])
  const api = useAPI()
  const [updatedRow, setUpdatedRow] = useState<number>()
  const loadAudioData = async () => {
    const language = getCurrentLanguge()
    const response = await api.get<AudioResponse[]>(
      `${endpoints.audioUrl()}?language=${language}`,
    )
    setAudioresponse(response.data)
  }

  useEffect(() => {
    async function loader() {
      await loadAudioData()
    }

    loader()
  }, [])

  return (
    <>
      <Box fontSize="xl" px="100" py="50">
        <Flex marginBottom="10" justifyContent="space-between">
          <TabSelector
            headers={Object.values(TabHeader)}
            selectedTab={selectedTab}
            setSelectedTab={setSelectedTab}
          />
          <Button
            leftIcon={<AddIcon />}
            variant="primary-button"
            onClick={onOpen}
          >
            Last opp
          </Button>
        </Flex>
        <AudioTable
          audioData={audioResponse.filter(
            (audio) => audio.type === tabHeaderToAudioType(selectedTab),
          )}
          reloadAudioData={loadAudioData}
          updatedRow={updatedRow}
          setUpdatedRow={setUpdatedRow}
        />
      </Box>
      <AudioForm
        reloadAudio={loadAudioData}
        mode="create"
        onClose={onClose}
        isOpen={isOpen}
        setUpdatedRow={setUpdatedRow}
      />
    </>
  )
}
