import { Table } from 'components/table'
import React, { useState, useRef, useMemo } from 'react'
import { Column, Row } from 'react-table'
import { endpoints } from 'utils/endpoints'
import { formatDate } from 'utils/formatDate'
import { useAPI } from 'utils/useAPI'
import { AudioTableData, AudioResponse } from 'types/audio'
import {
  Flex,
  Text,
  Image,
  Tooltip,
  useDisclosure,
  useToast,
} from '@chakra-ui/react'
import { AddIcon, DeleteIcon, EditIcon, HamburgerIcon } from '@chakra-ui/icons'
import { DeleteAlert } from 'components/alerts/DeleteAlert'
import { AudioForm } from 'components/AudioForm'
import { dateSorter, isCurrentlyAvailable } from 'utils/date-utils'
import { AddAudioTrackForm } from 'components/AddAudioTrackForm'
import { TrackOverviewModal } from 'components/TrackOverviewModal'

type Props = {
  audioData: AudioResponse[]
  reloadAudioData: () => void
  updatedRow?: number
  setUpdatedRow: (id: number) => void
}

export function AudioTable({
  audioData,
  reloadAudioData,
  updatedRow,
  setUpdatedRow,
}: Props) {
  const [actionObject, setActionObject] = useState<{
    action: 'delete' | 'update' | 'addTrack' | 'trackDetails'
    audio: AudioTableData
  } | null>(null)

  const api = useAPI()
  const toast = useToast()

  async function deleteAudio(audio: AudioTableData) {
    const response = await api.delete(endpoints.editAudioUrl(audio.id))

    if (response.status === 200) {
      reloadAudioData()
      setActionObject(null)

      toast({
        title: `Lydbok slettet.`,
        status: 'success',
        duration: 3000,
        isClosable: true,
        position: 'bottom-right',
      })
    } else {
      toast({
        title: `Lydbok ble ikke slettet.`,
        status: 'error',
        duration: null,
        isClosable: true,
        position: 'bottom-right',
      })
    }
  }

  // Modal
  const { isOpen, onOpen, onClose } = useDisclosure()
  const cancelRef = useRef(null)

  const coverPhotoUrlCell = ({
    value,
  }: {
    value: AudioTableData['coverPhotoUrl']
  }) => (value ? <Image src={value} height="100px" /> : null)

  const currentlyAvailableCell = ({
    value,
  }: {
    value: AudioTableData['currentlyAvailable']
  }) => (value ? <div>✅</div> : null)

  const actionsCell = ({ row }: { row: Row<AudioTableData> }) => {
    const { original: originalRow } = row

    return (
      <Flex justify="center">
        <Tooltip
          label="Legg til kapittel/episode"
          aria-label="Legg til epsiode"
        >
          <AddIcon
            boxSize="5"
            marginRight="5"
            _hover={{ cursor: 'pointer' }}
            onClick={() => {
              setActionObject({
                action: 'addTrack',
                audio: originalRow,
              })
              onOpen()
            }}
          />
        </Tooltip>
        <Tooltip label="Rediger" aria-label="Rediger lydbok">
          <EditIcon
            boxSize="5"
            marginRight="5"
            _hover={{ cursor: 'pointer' }}
            onClick={() => {
              setActionObject({
                action: 'update',
                audio: originalRow,
              })
              onOpen()
            }}
          />
        </Tooltip>
        <Tooltip label="Slett" aria-label="Slett lydbok">
          <DeleteIcon
            boxSize="5"
            marginRight="5"
            _hover={{ cursor: 'pointer' }}
            onClick={() => {
              setActionObject({
                action: 'delete',
                audio: originalRow,
              })
              onOpen()
            }}
          />
        </Tooltip>
        <Tooltip
          label="Oversikt over kapitler og episoder"
          aria-label="Oversikt over kapitler og episoder til lydbok og podkast"
        >
          <HamburgerIcon
            boxSize="5"
            _hover={{ cursor: 'pointer' }}
            onClick={() => {
              setActionObject({
                action: 'trackDetails',
                audio: originalRow,
              })
              onOpen()
            }}
          />
        </Tooltip>
      </Flex>
    )
  }

  const columns: Array<Column<AudioTableData>> = useMemo(
    () => [
      {
        Header: 'Forside',
        maxWidth: 100,
        accessor: 'coverPhotoUrl',
        Cell: coverPhotoUrlCell,
      },
      {
        Header: 'Vekting',
        accessor: 'order',
        maxWidth: 50,
      },
      {
        Header: 'Tittel',
        accessor: 'title',
      },
      {
        Header: 'Forfatter',
        accessor: 'author',
        maxWidth: 80,
      },
      {
        Header: 'Antall episoder',
        accessor: 'chapters',
        maxWidth: 50,
      },
      {
        Header: 'Tilgjengelig fra',
        accessor: 'availableFrom',
        Cell: ({ value }: { value: AudioTableData['availableFrom'] }) =>
          formatDate(value),
        maxWidth: 70,
        sortType: (data1, data2) =>
          dateSorter(
            data1.original.availableFrom,
            data2.original.availableFrom,
          ),
      },
      {
        Header: 'Tilgjengelig til',
        accessor: 'availableUntil',
        Cell: ({ value }: { value: AudioTableData['availableUntil'] }) =>
          formatDate(value),
        maxWidth: 70,
        sortType: (data1, data2) =>
          dateSorter(
            data1.original.availableUntil,
            data2.original.availableUntil,
          ),
      },
      {
        Header: 'Synlig i app',
        accessor: 'currentlyAvailable',
        Cell: currentlyAvailableCell,
        maxWidth: 70,
        sortType: (data1, data2) =>
          data1.original.currentlyAvailable &&
          !data2.original.currentlyAvailable
            ? 1
            : -1,
      },
      {
        id: 'actions',
        Header: <Text textAlign="center">Handlinger</Text>,
        accessor: 'id',
        maxWidth: 60,
        disableSortBy: true,
        Cell: actionsCell,
      },
    ],
    [],
  )

  const data: AudioTableData[] = useMemo(() => {
    const now = new Date()
    return (
      audioData.map((audio) => {
        const availableFrom = audio.availableFrom
          ? new Date(audio.availableFrom)
          : null
        const availableUntil = audio.availableUntil
          ? new Date(audio.availableUntil)
          : null

        const currentlyAvailable = isCurrentlyAvailable(
          now,
          availableFrom,
          availableUntil,
        )
        return {
          id: audio.id,
          title: audio.title,
          author: audio.author,
          order: audio.order,
          type: audio.type,
          size: audio.size,
          description: audio.description,
          availableFrom,
          availableUntil,
          chapters: audio.chapters,
          createdAt: audio.createdAt ? new Date(audio.createdAt) : null,
          updatedAt: audio.updatedAt ? new Date(audio.updatedAt) : null,
          currentlyAvailable,
          coverPhotoUrl: audio.coverPhotoUrl,
          language: audio.language,
        }
      }) ?? []
    )
  }, [audioData])

  return (
    <>
      <Table
        columns={columns}
        data={data}
        defaultSortBy={[{ id: 'order' }]}
        updatedRow={updatedRow}
      />
      {actionObject?.action === 'update' && (
        <AudioForm
          isOpen={isOpen}
          onClose={onClose}
          mode="update"
          reloadAudio={reloadAudioData}
          audio={{
            ...actionObject.audio,
          }}
          setUpdatedRow={setUpdatedRow}
        />
      )}
      {actionObject?.action === 'addTrack' && (
        <AddAudioTrackForm
          isOpen={isOpen}
          onClose={onClose}
          reloadAudio={reloadAudioData}
          audio={{
            ...actionObject.audio,
          }}
          setUpdatedRow={setUpdatedRow}
        />
      )}
      {actionObject?.action === 'delete' && (
        <DeleteAlert
          isOpen={isOpen}
          onClose={onClose}
          cancelRef={cancelRef}
          header="Slett lydbok"
          deletedObjectTitle={actionObject.audio.title}
          onClick={() => deleteAudio(actionObject.audio)}
        />
      )}
      {actionObject?.action === 'trackDetails' && (
        <TrackOverviewModal
          audio={actionObject.audio}
          isOpen={isOpen}
          onClose={onClose}
        />
      )}
    </>
  )
}
