import { useEffect, useState } from 'react'

export type Language = 'no' | 'se'

export function useLanguage(): [
  language: Language,
  setLanguage: (language: Language) => void,
] {
  const [selectedLanguage, setSelectedLanguage] = useState<Language>('no')

  function setNewLanguage(language: Language) {
    setSelectedLanguage(language)
    localStorage.setItem('language', language)
  }

  useEffect(() => {
    const savedLanguage = localStorage.getItem('language')

    if (savedLanguage && (savedLanguage === 'no' || savedLanguage === 'se')) {
      setSelectedLanguage(savedLanguage)
    }
  }, [])

  return [selectedLanguage, setNewLanguage]
}

export function getCurrentLanguge() {
  const savedLanguage = localStorage.getItem('language')
  return savedLanguage || 'no'
}
