import React from 'react'
import { Box, Heading, Button } from '@chakra-ui/react'
import { useMsal } from '@azure/msal-react'
import { loginRequest } from 'authConfig'

export function LandingPage() {
  const { instance } = useMsal()

  return (
    <Box
      backgroundColor="brand.lightGreen20"
      padding="100"
      display="flex"
      justifyContent="center"
      flexDirection="column"
      alignItems="center"
      height="100vh"
    >
      <Box
        padding="50"
        justifyContent="center"
        alignItems="center"
        display="flex"
        flexDirection="column"
      >
        <Heading color="brand.darkGrey">Entertainment Admin</Heading>
        <Button
          bg="brand.darkGrey"
          variant="primary-button"
          marginTop="30"
          onClick={() =>
            instance.loginRedirect(loginRequest).catch((e) => {
              console.log(e)
            })
          }
        >
          Logg in
        </Button>
      </Box>
    </Box>
  )
}
