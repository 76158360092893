import {
  Box,
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  Input,
  Textarea,
  useToast,
} from '@chakra-ui/react'
import React, { useState, useEffect } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { useAPI } from 'utils/useAPI'
import axios, { AxiosError, AxiosResponse, CancelTokenSource } from 'axios'
import { endpoints } from 'utils/endpoints'
import { AudioType, EditAudioTrackRequest, Track } from 'types/audio'
import { useMutation } from 'react-query'

type FormValues = {
  title: string | null
  description: string | null
}

type Props = {
  audioType: AudioType
  audioId: number
  trackNumber: number
  onClose: () => void
  reloadTracks: () => void
  setUpdatedRow: (trackNumber: number) => void
}

export function EditAudioTrackForm({
  audioType,
  audioId,
  trackNumber,
  onClose,
  reloadTracks,
  setUpdatedRow,
}: Props) {
  const [cancelToken, setCancelToken] = useState<
    CancelTokenSource | undefined
  >()

  useEffect(() => {
    setCancelToken(axios.CancelToken.source())
  }, [])

  const {
    handleSubmit,
    register,
    formState: { errors, isSubmitting },
    reset,
  } = useForm<FormValues>()

  const resetFormAndClose = async () => {
    reset({
      title: null,
      description: null,
    })
    cancelToken?.cancel()
    onClose()
  }

  const api = useAPI()
  const toast = useToast()

  const { mutate: sendRequest, isLoading } = useMutation<
    AxiosResponse<Track>,
    AxiosError<string>,
    EditAudioTrackRequest
  >((inputData) =>
    api.put(endpoints.editAudioTrack(audioId, trackNumber), inputData, {
      cancelToken: cancelToken!.token,
    }),
  )

  function getRequest(data: FormValues) {
    const formData = new FormData()
    if (data.title !== null) {
      formData.append('trackTitle', data.title)
    }
    if (data.description !== null) {
      formData.append('trackDescription', data.description)
    }

    return formData
  }

  const onSubmit: SubmitHandler<FormValues> = async (data) => {
    sendRequest(getRequest(data), {
      onSuccess: (response) => {
        reloadTracks()
        resetFormAndClose()
        setUpdatedRow(response.data.trackNumber)

        toast({
          title: `${
            audioType === AudioType.AUDIOBOOK ? 'Kapittel' : 'Episode'
          } oppdatert.`,
          status: 'success',
          duration: 3000,
          isClosable: true,
          position: 'bottom-right',
        })
      },

      onError: () => {
        toast({
          title: `Noe gikk galt. ${
            audioType === AudioType.AUDIOBOOK ? 'Kapittel' : 'Episode'
          } ble ikke endret, prøv igjen.`,
          status: 'error',
          duration: 4000,
          isClosable: true,
          position: 'bottom-right',
        })
      },
    })
  }

  return (
    <>
      <Heading>{`Oppdater kapittel ${trackNumber}`}</Heading>
      <form onSubmit={handleSubmit(onSubmit)}>
        <FormControl isInvalid={errors.title !== undefined} marginTop="15px">
          <FormLabel htmlFor="title">Tittel</FormLabel>
          <Input
            id="title"
            placeholder="Skriv inn her"
            {...register('title', {
              minLength: {
                value: 2,
                message: 'Minimumslengde er 2',
              },
            })}
          />
          <FormErrorMessage>
            {errors.title && errors.title.message}
          </FormErrorMessage>
        </FormControl>
        <FormControl
          isInvalid={errors.description !== undefined}
          marginTop="15px"
        >
          <FormLabel htmlFor="description">Beskrivelse</FormLabel>
          <Textarea
            id="description"
            placeholder="Skriv inn her"
            minHeight="150"
            {...register('description', {
              minLength: {
                value: 2,
                message: 'Minimumslengde er 2',
              },
            })}
          />
          <FormErrorMessage>
            {errors.description && errors.description.message}
          </FormErrorMessage>
        </FormControl>

        <Box marginTop={5} marginBottom={5}>
          <Button
            variant="primary-button"
            mr={3}
            isLoading={isSubmitting || isLoading}
            type="submit"
          >
            {`Oppdater ${
              audioType === AudioType.AUDIOBOOK ? 'kapittel' : 'episode'
            }`}
          </Button>
          <Button onClick={resetFormAndClose}>Avbryt</Button>
        </Box>
      </form>
    </>
  )
}
