import { Box, Text } from '@chakra-ui/react'
import React from 'react'
import { Link } from 'react-router-dom'
import { UrlPath } from 'utils/paths'

export type MediaType = 'pdfFiles' | 'audio' | null

interface HeaderLinkProps {
  text: string
  urlPath: UrlPath
  mediaType: MediaType
  selectedMediaType: MediaType
}

export function HeaderLink({
  text,
  urlPath,
  mediaType,
  selectedMediaType,
}: HeaderLinkProps) {
  return (
    <Box>
      <Link to={urlPath}>
        <Text
          fontSize="2xl"
          textDecoration={
            selectedMediaType === mediaType ? 'underline' : 'none'
          }
          paddingLeft="30"
        >
          {text}
        </Text>
      </Link>
    </Box>
  )
}
