import { LogLevel } from '@azure/msal-browser'

export const msalConfig = {
  auth: {
    clientId: '423d8751-f4b6-42ac-889c-2edb50eeaa1d',
    authority: 'https://login.microsoftonline.com/nsbas.onmicrosoft.com',
  },
  cache: {
    cacheLocation: 'sessionStorage',
    storeAuthStateInCookie: false,
  },
  system: {
    loggerOptions: {
      loggerCallback: (
        level: LogLevel,
        message: string,
        containsPii: boolean,
      ) => {
        if (containsPii) {
          return
        }
        switch (level) {
          case LogLevel.Error:
            console.error(message)
            return
          case LogLevel.Info:
            console.info(message)
            return
          case LogLevel.Verbose:
            console.debug(message)
            return
          case LogLevel.Warning:
            console.warn(message)
            break
          default:
            break
        }
      },
    },
  },
}

export const loginRequest = {
  scopes: ['api://423d8751-f4b6-42ac-889c-2edb50eeaa1d/entertainment.admin'],
}
