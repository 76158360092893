import {
  Button,
  FormControl,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalOverlay,
  FormErrorMessage,
  Box,
  useToast,
  Checkbox,
  Select,
} from '@chakra-ui/react'
import { AxiosError, AxiosResponse } from 'axios'
import React, { useEffect } from 'react'
import { useForm, SubmitHandler, Controller } from 'react-hook-form'
import { useMutation } from 'react-query'
import {
  IssuerCategory,
  IssuerRequest,
  IssuerResponse,
  IssuerTableData,
  IssuerUpdateRequest,
} from 'types/issuers'
import { endpoints } from 'utils/endpoints'
import { Language, useLanguage } from 'utils/language-util'
import { useAPI } from 'utils/useAPI'

type IssuerCreateInput = {
  mode: 'create'
  issuer?: undefined
}

type IssuerUpdateInput = {
  mode: 'update'
  issuer: IssuerTableData
}

type IssuerFormInput = {
  defaultCategory: IssuerCategory
  isOpen: boolean
  onClose: () => void
  reloadIssuers: () => void
  setUpdatedRow: (id: number) => void
} & (IssuerCreateInput | IssuerUpdateInput)

type FormValues = {
  category: IssuerCategory
  title: string
  autoPublish: boolean
  defaultOrder: number
  language: Language
}

export function IssuerForm(props: IssuerFormInput) {
  const {
    isOpen,
    reloadIssuers,
    onClose,
    mode,
    issuer,
    setUpdatedRow,
    defaultCategory,
  } = props

  const {
    register,
    reset,
    setValue,
    handleSubmit,
    formState: { errors, isSubmitting },
    control,
  } = useForm<FormValues>()

  const [language] = useLanguage()

  useEffect(() => {
    if (isOpen && issuer) {
      if (issuer.category) {
        setValue('category', issuer.category)
      }
      if (issuer.autoPublish) {
        setValue('autoPublish', issuer.autoPublish)
      }
      if (issuer.title) {
        setValue('title', issuer.title)
      }
      if (issuer.defaultOrder) {
        setValue('defaultOrder', issuer.defaultOrder)
      }
      if (issuer.language) {
        setValue('language', issuer.language)
      }
    }
  }, [isOpen, issuer])

  const api = useAPI()

  const { mutate } = useMutation<
    AxiosResponse<IssuerResponse>,
    AxiosError<string>,
    IssuerRequest | IssuerUpdateRequest
  >((inputData) => {
    if (mode === 'create') {
      return api.post(`${endpoints.issuersUrl()}`, inputData)
    } else {
      return api.put(`${endpoints.issuerUrl(issuer!.id)}`, inputData)
    }
  })

  const toast = useToast()

  function getToastMessage() {
    if (mode === 'create') {
      return 'opprettet'
    } else {
      return 'oppdatert'
    }
  }

  function getRequest(data: FormValues): IssuerRequest | IssuerUpdateRequest {
    if (mode === 'create') {
      return {
        title: data.title,
        category: data.category,
        autoPublish: data.autoPublish,
        defaultOrder: data.defaultOrder,
        language: data.language,
      }
    } else {
      return {
        title: data.title,
        category: issuer!.category,
        ftpUser: issuer!.ftpUser,
        autoPublish: data.autoPublish,
        defaultOrder: data.defaultOrder,
        language: data.language,
      }
    }
  }

  const closeModal = () => {
    reset({
      title: undefined,
      autoPublish: false,
      defaultOrder: undefined,
    })
    onClose()
  }

  const onSubmit: SubmitHandler<FormValues> = (data) => {
    const request = getRequest(data)

    mutate(request, {
      onSuccess: (response) => {
        setUpdatedRow(response.data.id)
        toast({
          title: `Utgiver ${getToastMessage()}.`,
          status: 'success',
          duration: 3000,
          isClosable: true,
          position: 'bottom-right',
        })
        reloadIssuers()
        closeModal()
      },
      onError: (error) => {
        if (error.response?.status === 409) {
          toast({
            title: `Utgiver ble ikke ${getToastMessage()}.`,
            status: 'error',
            description: 'En utgiver med dette navnet finnes allerede.',
            duration: 5000,
            isClosable: true,
            position: 'bottom-right',
          })
        } else {
          toast({
            title: `Utgiver ble ikke ${getToastMessage()}.`,
            status: 'error',
            duration: 5000,
            isClosable: true,
            position: 'bottom-right',
          })
        }
      },
    })
  }

  const initialRef = React.useRef(null)
  const finalRef = React.useRef(null)

  return (
    <Box ref={finalRef}>
      <Modal
        initialFocusRef={initialRef}
        finalFocusRef={finalRef}
        isOpen={isOpen}
        onClose={closeModal}
        isCentered
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            {mode === 'create' ? 'Opprett utgiver' : 'Oppdater utgiver'}
          </ModalHeader>
          <ModalCloseButton />
          <form onSubmit={handleSubmit(onSubmit)}>
            <ModalBody pb={6}>
              <FormControl isRequired marginBottom="5">
                <FormLabel htmlFor="language">Land</FormLabel>
                <Select
                  id="language"
                  {...register('language')}
                  defaultValue={language}
                >
                  <option key="no" value="no">
                    🇳🇴
                  </option>
                  <option key="se" value="se">
                    🇸🇪
                  </option>
                </Select>
              </FormControl>
              <FormControl isRequired marginBottom="5">
                <FormLabel htmlFor="category">Type</FormLabel>
                <Select
                  id="category"
                  {...register('category')}
                  defaultValue={defaultCategory}
                >
                  <option key="MAGAZINE" value="MAGAZINE">
                    Magasin
                  </option>
                  <option key="NEWSPAPER" value="NEWSPAPER">
                    Avis
                  </option>
                </Select>
              </FormControl>
              <FormControl isInvalid={errors.title !== undefined}>
                <FormLabel htmlFor="title">Navn på utgiver</FormLabel>
                <Input
                  id="title"
                  placeholder="Skriv inn her"
                  defaultValue={issuer?.title}
                  {...register('title', {
                    required: 'Dette feltet er påkrevd',
                    minLength: {
                      value: 2,
                      message: 'Minimumslengde er 2',
                    },
                  })}
                />
                <FormErrorMessage>
                  {errors.title && errors.title.message}
                </FormErrorMessage>
              </FormControl>
              <FormControl
                isInvalid={errors.defaultOrder !== undefined}
                marginTop="5"
              >
                <FormLabel htmlFor="defaultOrder">
                  Rekkefølge på ny utgave
                </FormLabel>
                <Input
                  id="defaultOrder"
                  placeholder="Skriv inn her"
                  defaultValue={issuer?.defaultOrder}
                  {...register('defaultOrder', {
                    required: 'Dette feltet er påkrevd',
                    minLength: {
                      value: 1,
                      message: 'Minimumslengde er 1',
                    },
                  })}
                />
                <FormErrorMessage>
                  {errors.defaultOrder && errors.defaultOrder.message}
                </FormErrorMessage>
              </FormControl>
              <FormControl
                isInvalid={errors.autoPublish !== undefined}
                marginTop={5}
              >
                <Controller
                  control={control}
                  name="autoPublish"
                  defaultValue
                  render={({ field: { onChange, value, ref } }) => (
                    <Checkbox onChange={onChange} ref={ref} isChecked={value}>
                      Automatisk publiser ny opplasting
                    </Checkbox>
                  )}
                />
              </FormControl>
            </ModalBody>
            <ModalFooter>
              <Button
                variant="primary-button"
                mr={3}
                isLoading={isSubmitting}
                type="submit"
              >
                {mode === 'create' ? 'Opprett' : 'Oppdater'}
              </Button>
              <Button onClick={closeModal}>Avbryt</Button>
            </ModalFooter>
          </form>
        </ModalContent>
      </Modal>
    </Box>
  )
}
