import {
  Table as ChakraTable,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  chakra,
} from '@chakra-ui/react'
import { TriangleDownIcon, TriangleUpIcon } from '@chakra-ui/icons'
import {
  Column,
  useTable,
  useSortBy,
  useFlexLayout,
  SortingRule,
} from 'react-table'
import React from 'react'

type DefaultColumn = {
  minWidth: number
  width: number
  maxWidth: number
}

type TableDataBaseType = Record<string, string | number | boolean | Date | null>

type TableProps<TableDataType extends TableDataBaseType> = {
  columns: Array<Column<TableDataType>>
  data: TableDataType[]
  defaultColumn?: DefaultColumn
  defaultSortBy?: Array<SortingRule<TableDataType>>
  updatedRow?: number
}

export function Table<DataType extends TableDataBaseType>({
  columns,
  data,
  defaultColumn,
  defaultSortBy,
  updatedRow,
}: TableProps<DataType>) {
  const tableInstance = useTable<DataType>(
    {
      columns,
      data,
      defaultColumn,
      initialState: defaultSortBy && { sortBy: defaultSortBy },
    },
    useSortBy,
    useFlexLayout,
  )
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    tableInstance

  return (
    <ChakraTable size="sm" {...getTableProps()}>
      <Thead>
        {headerGroups.map((headerGroup) => (
          <Tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column) => (
              <Th
                paddingY="4"
                {...column.getHeaderProps(column.getSortByToggleProps())}
              >
                {column.render('Header')}
                {column.isSorted ? (
                  <chakra.span pl="3">
                    {column.isSortedDesc ? (
                      <TriangleDownIcon aria-label="sorted descending" />
                    ) : (
                      <TriangleUpIcon aria-label="sorted ascending" />
                    )}
                  </chakra.span>
                ) : null}
              </Th>
            ))}
          </Tr>
        ))}
      </Thead>
      <Tbody {...getTableBodyProps()}>
        {rows.map((row) => {
          prepareRow(row)
          return (
            <Tr
              bgColor={
                (row.original.id === updatedRow ||
                  row.original.trackNumber === updatedRow) &&
                updatedRow !== undefined
                  ? 'green.100'
                  : undefined
              }
              {...row.getRowProps()}
            >
              {row.cells.map((cell) => (
                <Td paddingY="3" {...cell.getCellProps()}>
                  {cell.render('Cell')}
                </Td>
              ))}
            </Tr>
          )
        })}
      </Tbody>
    </ChakraTable>
  )
}
