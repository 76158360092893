import { addYears, isBefore, isWithinInterval } from 'date-fns'

export function dateSorter(date1: Date | null, date2: Date | null) {
  if (date1 && !date2) {
    return 1
  } else if (date1 && date2) {
    return isBefore(new Date(date1), new Date(date2)) ? 1 : -1
  } else {
    return -1
  }
}

export function isCurrentlyAvailable(
  now: Date,
  start: Date | null,
  end: Date | null,
) {
  if (start && end && end < start) {
    return false
  }

  return start
    ? isWithinInterval(now, {
        start,
        end: end || addYears(start, 10),
      })
    : false
}
