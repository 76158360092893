import React from 'react'
import ReactDOM from 'react-dom'
import { ChakraProvider } from '@chakra-ui/react'
import { HashRouter as Router } from 'react-router-dom'
import { App } from 'App'
import { vyColorScheme } from 'vyColorScheme'
import { PublicClientApplication } from '@azure/msal-browser'
import { MsalProvider } from '@azure/msal-react'
import { msalConfig } from 'authConfig'

// Polifyll
Object.fromEntries = (l: any) =>
  l.reduce((a: any, [k, v]: any) => ({ ...a, [k]: v }), {})

const msalInstance = new PublicClientApplication(msalConfig)

ReactDOM.render(
  <React.StrictMode>
    <MsalProvider instance={msalInstance}>
      <Router>
        <ChakraProvider theme={vyColorScheme}>
          <App />
        </ChakraProvider>
      </Router>
    </MsalProvider>
  </React.StrictMode>,
  document.getElementById('root'),
)
