import {
  Box,
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Textarea,
  useToast,
} from '@chakra-ui/react'
import React, { useCallback, useEffect, useState } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { useAPI } from 'utils/useAPI'
import { endpoints } from 'utils/endpoints'
import { useMutation } from 'react-query'
import axios, { AxiosError, AxiosResponse, CancelTokenSource } from 'axios'
import {
  AudioResponse,
  AudioTableData,
  AddAudioTrackRequest,
} from 'types/audio'

import { FileInput, FileInputList } from 'components/file-input'
import { FileRejection } from 'react-dropzone'
import { ProgressBar } from 'components/ProgressBar'

type FormInput = {
  isOpen: boolean
  onClose: () => void
  reloadAudio: () => void
  setUpdatedRow: (id: number) => void
} & AddAudioTrackInput

type FormValues = {
  audioId: number
  title: string | null
  description: string | null
  audioTrackFile?: File
}

type AddAudioTrackInput = {
  audio: AudioTableData
}

export function AddAudioTrackForm(props: FormInput) {
  const { isOpen, reloadAudio, onClose, setUpdatedRow, audio } = props
  const [uploadProgress, setUploadProgress] = useState(0)
  const [cancelToken, setCancelToken] = useState<
    CancelTokenSource | undefined
  >()

  useEffect(() => {
    if (isOpen) {
      setUploadProgress(0)
      setCancelToken(axios.CancelToken.source())
    }
  }, [isOpen])

  const {
    handleSubmit,
    register,
    watch,

    formState: { errors, isSubmitting },
    reset,
    setValue,
    clearErrors,
    setError,
    getValues,
  } = useForm<FormValues>()

  const resetFormAndClose = async () => {
    reset({
      audioId: undefined,
      title: undefined,
      description: undefined,
      audioTrackFile: undefined,
    })
    cancelToken?.cancel()
    onClose()
  }

  const api = useAPI()
  const toast = useToast()
  const initialRef = React.useRef(null)
  const finalRef = React.useRef(null)

  useEffect(() => {}, [isOpen, audio])

  const { mutate: sendRequest, isLoading } = useMutation<
    AxiosResponse<AudioResponse>,
    AxiosError<string>,
    AddAudioTrackRequest
  >((inputData) =>
    api.post(endpoints.audioTracksUrl(audio.id), inputData, {
      headers: {
        // 'Content-Type': 'multipart/form-data',
        // 'Content-Disposition': 'form-data; name="audioMp3"; filename="Test"',
        'Content-Type': 'audio/mpeg',
      },
      onUploadProgress: (progress) => {
        setUploadProgress(progress.loaded / (progress.total ?? 1))
      },
      cancelToken: cancelToken!.token,
    }),
  )

  function getRequest(data: FormValues) {
    const formData = new FormData()
    if (data.title !== null) {
      formData.append('trackTitle', data.title)
    }
    if (data.description !== null) {
      formData.append('trackDescription', data.description)
    }
    formData.append('audioMp3', data.audioTrackFile!)

    const metadata = {
      part_content_type: 'audio/mpeg',
    }
    formData.append('metadata', JSON.stringify(metadata))
    return formData
  }

  const onSubmit: SubmitHandler<FormValues> = async (data) => {
    if (!data.audioTrackFile) {
      toast({
        title: `Du må laste opp en .mp3 fil.`,
        status: 'error',
        duration: null,
        isClosable: true,
        position: 'bottom-right',
      })
      return
    }

    sendRequest(getRequest(data), {
      onSuccess: (response) => {
        reloadAudio()
        resetFormAndClose()
        setUpdatedRow(response.data.id)

        toast({
          title: `Episode lagt til.`,
          status: 'success',
          duration: 3000,
          isClosable: true,
          position: 'bottom-right',
        })
      },

      onError: () => {
        toast({
          title: `Episode ble ikke lagt til.`,
          status: 'error',
          duration: 4000,
          isClosable: true,
          position: 'bottom-right',
        })
      },
    })
  }

  const onDropCallback = useCallback(
    (acceptedFiles: File[], fileRejections: FileRejection[]) => {
      clearErrors('audioTrackFile')

      if (fileRejections.length > 0) {
        setError('audioTrackFile', {
          type: 'manual',
          message: 'Kun én fil kan lastes opp, av typen .mp3',
        })
      }

      setValue('audioTrackFile', acceptedFiles ? acceptedFiles[0] : undefined)
    },
    [getValues('audioTrackFile')],
  )

  return (
    <Box ref={finalRef}>
      <Modal
        initialFocusRef={initialRef}
        finalFocusRef={finalRef}
        isOpen={isOpen}
        onClose={resetFormAndClose}
      >
        <ModalOverlay />
        <ModalContent minWidth="700">
          <ModalHeader>{`Legg til episode i ${audio.title}`}</ModalHeader>
          <ModalCloseButton />
          <form onSubmit={handleSubmit(onSubmit)}>
            <ModalBody>
              <>
                <FormControl
                  isInvalid={errors.title !== undefined}
                  marginTop="15px"
                >
                  <FormLabel htmlFor="title">Tittel</FormLabel>
                  <Input
                    id="title"
                    placeholder="Skriv inn her"
                    {...register('title', {
                      minLength: {
                        value: 2,
                        message: 'Minimumslengde er 2',
                      },
                    })}
                  />
                  <FormErrorMessage>
                    {errors.title && errors.title.message}
                  </FormErrorMessage>
                </FormControl>
                <FormControl
                  isInvalid={errors.description !== undefined}
                  marginTop="15px"
                >
                  <FormLabel htmlFor="description">Beskrivelse</FormLabel>
                  <Textarea
                    id="description"
                    placeholder="Skriv inn her"
                    minHeight="150"
                    {...register('description', {
                      minLength: {
                        value: 2,
                        message: 'Minimumslengde er 2',
                      },
                    })}
                  />
                  <FormErrorMessage>
                    {errors.description && errors.description.message}
                  </FormErrorMessage>
                </FormControl>
                <FormControl
                  isRequired
                  isInvalid={errors.audioTrackFile !== undefined}
                  paddingTop="5"
                >
                  <FormLabel htmlFor="audioTrackFile">Fil</FormLabel>
                  <FileInput
                    id="audioTrackFile"
                    onDrop={onDropCallback}
                    maxFiles={1}
                    accept=".mp3"
                  />
                  <FileInputList
                    files={
                      watch('audioTrackFile')
                        ? ([getValues('audioTrackFile')] as File[])
                        : ([] as File[])
                    }
                    setFiles={(newFiles) => {
                      setValue(
                        'audioTrackFile',
                        newFiles ? newFiles[0] : undefined,
                      )
                    }}
                  />
                  <FormErrorMessage>
                    {errors.audioTrackFile && errors.audioTrackFile.message}
                  </FormErrorMessage>
                </FormControl>
              </>
            </ModalBody>
            <ProgressBar progress={uploadProgress} isLoading={isLoading} />

            <ModalFooter style={{ marginTop: '20px' }}>
              <Button
                variant="primary-button"
                mr={3}
                isLoading={isSubmitting || isLoading}
                type="submit"
              >
                Legg til episode
              </Button>
              <Button onClick={resetFormAndClose}>Avbryt</Button>
            </ModalFooter>
          </form>
        </ModalContent>
      </Modal>
    </Box>
  )
}
