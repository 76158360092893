import axios from 'axios'
import { useEffect } from 'react'
import { useMsal } from '@azure/msal-react'
import { loginRequest } from 'authConfig'

export function useAPI() {
  const { instance, accounts } = useMsal()
  const axiosInstance = axios.create()

  useEffect(() => {
    // Intercept axios request
    axiosInstance.interceptors.request.use(async (config) => {
      const token = (
        await instance.acquireTokenSilent({
          ...loginRequest,
          account: accounts[0],
        })
      ).accessToken
      const newConfig = config
      newConfig.headers.Authorization = `Bearer ${token}`
      return newConfig
    })
  })

  return axiosInstance
}
