import React from 'react'

type Props = {
  progress: number
  isLoading: boolean
}

export function ProgressBar({ progress, isLoading }: Props) {
  if (progress === 0 || !isLoading) {
    return null
  }

  const roundedProgress = (progress * 100).toFixed(0)

  return (
    <div
      style={{
        display: 'flex',
        flex: 1,
        marginTop: '20px',
        marginRight: '25px',
        marginLeft: '25px',
        flexDirection: 'column',
      }}
    >
      <div
        style={{
          borderRadius: '5px',
          backgroundColor: '#00866E',
          height: '10px',
          width: `${roundedProgress}%`,
        }}
      />
      <div style={{ alignSelf: 'center', marginTop: '10px' }}>{`${
        progress === 1 ? '99' : roundedProgress
      } %`}</div>
      {progress === 1 && (
        <div
          style={{
            marginTop: '20px',
            marginLeft: '25px',
            marginRight: '25px',
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          {'>> Laster opp filer til S3. Det kan ta litt tid. <<'}
        </div>
      )}
    </div>
  )
}
