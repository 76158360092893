import React, { useEffect, useState } from 'react'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import { useMsal } from '@azure/msal-react'
import {
  Box,
  Text,
  Menu,
  MenuButton,
  IconButton,
  MenuItem,
  MenuList,
  Switch,
  useColorModeValue,
  FormControl,
  FormLabel,
  useColorMode,
  RadioGroup,
  Radio,
  Center,
} from '@chakra-ui/react'

import { HamburgerIcon } from '@chakra-ui/icons'
import { VyLogo } from 'assets/VyLogo'
import { UrlPath } from 'utils/paths'
import { HeaderLink, MediaType } from 'components/header/HeaderLink'
import { Language, useLanguage } from 'utils/language-util'

function Header({ location }: RouteComponentProps) {
  const { instance } = useMsal()
  const { colorMode, toggleColorMode } = useColorMode()
  const [selectedMediaType, setSelectedMediaType] = useState<MediaType>(null)
  const [language, setLanguage] = useLanguage()

  const updateLanguage = (lan: Language) => {
    setLanguage(lan)
    window.location.reload()
  }

  useEffect(() => {
    if (location.pathname === UrlPath.PDF_FILES) {
      setSelectedMediaType('pdfFiles')
    } else if (location.pathname === UrlPath.AUDIO) {
      setSelectedMediaType('audio')
    } else {
      setSelectedMediaType(null)
    }
  }, [location])

  return (
    <Box
      backgroundColor={useColorModeValue('brand.white', 'gray.800')}
      alignItems="center"
      display="flex"
      paddingX="30"
      shadow="md"
      justifyContent="space-between"
    >
      <Box alignItems="center" display="flex">
        <VyLogo />
        <Text fontSize="3xl" paddingLeft="30">
          Entertainment Admin
        </Text>
      </Box>
      <HeaderLink
        text="Lyd"
        urlPath={UrlPath.AUDIO}
        mediaType="audio"
        selectedMediaType={selectedMediaType}
      />
      <HeaderLink
        text="Aviser og Magasiner"
        urlPath={UrlPath.PDF_FILES}
        mediaType="pdfFiles"
        selectedMediaType={selectedMediaType}
      />
      <Box display="flex">
        <Center marginRight="30px">
          <RadioGroup onChange={updateLanguage} value={language}>
            <Radio value="no" marginRight="10px">
              🇳🇴
            </Radio>
            <Radio value="se">🇸🇪</Radio>
          </RadioGroup>
        </Center>

        <Menu>
          <MenuButton
            as={IconButton}
            aria-label="Options"
            icon={<HamburgerIcon />}
            variant="outline"
          />
          <MenuList>
            <MenuItem>
              <FormControl display="flex" alignItems="center">
                <FormLabel
                  htmlFor="dark-mode-switchs"
                  fontWeight="normal"
                  mb="0"
                >
                  Dark mode
                </FormLabel>
                <Switch
                  id="dark-mode-switch"
                  defaultChecked={colorMode === 'dark'}
                  onChange={toggleColorMode}
                />
              </FormControl>
            </MenuItem>
            <MenuItem
              onClick={() =>
                instance.logoutRedirect({
                  postLogoutRedirectUri: '/',
                })
              }
            >
              Logg ut
            </MenuItem>
          </MenuList>
        </Menu>
      </Box>
    </Box>
  )
}

export const HeaderWithRouter = withRouter(Header)
