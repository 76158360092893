import React, { useEffect, useState, useMemo } from 'react'
import {
  Box,
  Button,
  Flex,
  Spacer,
  Switch,
  useDisclosure,
} from '@chakra-ui/react'
import { TabSelector } from 'components/TabSelector'
import { IssuersTable, TabHeader } from 'components/table'
import { AddIcon } from '@chakra-ui/icons'
import { IssuerForm } from 'components/IssuerForm'
import { PDFForm } from 'components/PDFForm'
import { PDFResponse } from 'types/pdf'
import { IssuerResponse } from 'types/issuers'
import { useAPI } from 'utils/useAPI'
import { endpoints } from 'utils/endpoints'
import { addMinutes, isBefore } from 'date-fns'
import { PDFTable } from 'components/table/PDFTable'
import { getCurrentLanguge } from 'utils/language-util'

export function PDFPage() {
  const [selectedTab, setSelectedTab] = useState<TabHeader>(TabHeader.MAGAZINES)
  const [magazineIssuers, setMagazineIssuers] = useState<IssuerResponse[]>([])
  const [newspaperIssuers, setNewspaperIssuers] = useState<IssuerResponse[]>([])
  const [newspapers, setNewspapers] = useState<PDFResponse[]>([])
  const [magazines, setMagazines] = useState<PDFResponse[]>([])
  const [updatedRow, setUpdatedRow] = useState<number>()
  const [showOldIssues, setShowOldIssues] = useState(false)

  const { isOpen, onOpen, onClose } = useDisclosure()
  const api = useAPI()
  const language = getCurrentLanguge()

  const loadPDFfiles = async () => {
    const pdfFilesResp = await api.get<PDFResponse[]>(
      `${endpoints.pdfsUrl()}?language=${language}`,
    )
    setMagazines(pdfFilesResp.data.filter((pdf) => pdf.category === 'MAGAZINE'))
    setNewspapers(
      pdfFilesResp.data.filter((pdf) => pdf.category === 'NEWSPAPER'),
    )
  }

  const loadIssuers = async () => {
    const issuersResp = await api.get<IssuerResponse[]>(
      `${endpoints.issuersUrl()}?language=${language}`,
    )
    const newsIssuers = issuersResp.data.filter(
      (data) => data.category === 'NEWSPAPER',
    )

    const magIssuers = issuersResp.data.filter(
      (data) => data.category === 'MAGAZINE',
    )

    setNewspaperIssuers(newsIssuers)
    setMagazineIssuers(magIssuers)
  }

  useEffect(() => {
    async function loader() {
      Promise.all([loadPDFfiles(), loadIssuers()])
    }

    loader()
  }, [])

  const filteredMagazines = useMemo(() => {
    if (showOldIssues) {
      return magazines
    } else {
      const now = new Date()
      return magazines.filter((magazine) => {
        const unpublished = magazine.unpublishedAt
          ? new Date(magazine.unpublishedAt)
          : addMinutes(now, 10)
        return isBefore(now, unpublished)
      })
    }
  }, [magazines, showOldIssues])

  const filteredNewspapers = useMemo(() => {
    if (showOldIssues) {
      return newspapers
    } else {
      const now = new Date()
      return newspapers.filter((newspaper) => {
        const unpublished = newspaper.unpublishedAt
          ? new Date(newspaper.unpublishedAt)
          : addMinutes(now, 10)
        return isBefore(now, unpublished)
      })
    }
  }, [newspapers, showOldIssues])

  return (
    <Box fontSize="xl" px="100" py="50">
      <Flex marginBottom="10">
        <TabSelector
          headers={Object.values(TabHeader)}
          selectedTab={selectedTab}
          setSelectedTab={setSelectedTab}
        />
        <Spacer />
        {(selectedTab === TabHeader.MAGAZINE_ISSUERS ||
          selectedTab === TabHeader.NEWSPAPER_ISSUERS) && (
          <Button
            leftIcon={<AddIcon />}
            variant="primary-button"
            onClick={onOpen}
          >
            Opprett
          </Button>
        )}
        {(selectedTab === TabHeader.MAGAZINES ||
          selectedTab === TabHeader.NEWSPAPERS) && (
          <Button
            leftIcon={<AddIcon />}
            variant="primary-button"
            onClick={onOpen}
          >
            Last opp
          </Button>
        )}
      </Flex>
      {(selectedTab === TabHeader.MAGAZINES ||
        selectedTab === TabHeader.NEWSPAPERS) && (
        <>
          <Switch
            marginBottom="5"
            isChecked={showOldIssues}
            onChange={() => {
              setShowOldIssues(!showOldIssues)
            }}
          >
            Vis eldre utgaver
          </Switch>
          <PDFTable
            reload={loadPDFfiles}
            pdfFiles={
              selectedTab === TabHeader.MAGAZINES
                ? filteredMagazines
                : filteredNewspapers
            }
            issuersData={
              selectedTab === TabHeader.MAGAZINES
                ? magazineIssuers
                : newspaperIssuers
            }
            updatedRow={updatedRow}
            setUpdatedRow={setUpdatedRow}
          />
          <PDFForm
            mode="create"
            issuersData={
              selectedTab === TabHeader.MAGAZINES
                ? magazineIssuers
                : newspaperIssuers
            }
            reloadPdfFiles={loadPDFfiles}
            onClose={onClose}
            isOpen={isOpen}
            setUpdatedRow={setUpdatedRow}
          />
        </>
      )}
      {(selectedTab === TabHeader.MAGAZINE_ISSUERS ||
        selectedTab === TabHeader.NEWSPAPER_ISSUERS) && (
        <>
          <IssuersTable
            reloadIssuers={loadIssuers}
            issuersData={
              selectedTab === TabHeader.MAGAZINE_ISSUERS
                ? magazineIssuers
                : newspaperIssuers
            }
            updatedRow={updatedRow}
            setUpdatedRow={setUpdatedRow}
          />
          <IssuerForm
            mode="create"
            isOpen={isOpen}
            onClose={onClose}
            reloadIssuers={loadIssuers}
            setUpdatedRow={setUpdatedRow}
            defaultCategory={
              selectedTab === TabHeader.MAGAZINE_ISSUERS
                ? 'MAGAZINE'
                : 'NEWSPAPER'
            }
          />
        </>
      )}
    </Box>
  )
}
