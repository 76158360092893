import React from 'react'
import { Input, InputProps } from '@chakra-ui/react'

type DatetimePickerProps = {} & InputProps

export const DatetimePicker = React.forwardRef<
  HTMLInputElement,
  DatetimePickerProps
>((props: DatetimePickerProps, ref) => {
  const { onChange, value, defaultValue } = props

  return (
    <Input
      type="datetime-local"
      onChange={onChange}
      value={value || defaultValue || ''}
      ref={ref}
    />
  )
})
