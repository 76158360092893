import React, { useState, useMemo, useRef } from 'react'
import { Column, Row } from 'react-table'
import { Table } from 'components/table/Table'
import { endpoints } from 'utils/endpoints'
import { formatDate } from 'utils/formatDate'
import { useAPI } from 'utils/useAPI'
import {
  Flex,
  Text,
  Tooltip,
  Image,
  useDisclosure,
  useToast,
} from '@chakra-ui/react'
import { DeleteIcon, EditIcon, ViewIcon } from '@chakra-ui/icons'
import { PDFResponse, PDFTableData, S3ResponseModel } from 'types/pdf'

import { DeleteAlert } from 'components/alerts/DeleteAlert'
import { PDFForm } from 'components/PDFForm'
import { IssuerResponse } from 'types/issuers'
import { dateSorter, isCurrentlyAvailable } from 'utils/date-utils'

type Props = {
  pdfFiles: PDFResponse[]
  issuersData: IssuerResponse[]
  reload: () => void
  updatedRow?: number
  setUpdatedRow: (id: number) => void
}

export function PDFTable({
  pdfFiles,
  issuersData,
  reload,
  updatedRow,
  setUpdatedRow,
}: Props) {
  const [actionObject, setActionObject] = useState<{
    action: 'delete' | 'update'
    pdfFile: PDFTableData
  } | null>(null)

  const api = useAPI()
  const toast = useToast()

  async function deletePdfFile(pdfFile: PDFTableData) {
    const response = await api.delete(endpoints.editPdfsUrl(pdfFile.id))

    if (response.status === 200) {
      reload()
      setActionObject(null)
      toast({
        title: `Fil slettet.`,
        status: 'success',
        duration: 3000,
        isClosable: true,
        position: 'bottom-right',
      })
    } else {
      toast({
        title: `Fil ble ikke slettet.`,
        status: 'error',
        duration: null,
        isClosable: true,
        position: 'bottom-right',
      })
    }
  }

  const data: PDFTableData[] = useMemo(() => {
    const now = new Date()

    return pdfFiles.map((pdfFile) => {
      const publishedAt = pdfFile.publishedAt
        ? new Date(pdfFile.publishedAt)
        : null
      const unPublishedAt = pdfFile.unpublishedAt
        ? new Date(pdfFile.unpublishedAt)
        : null

      const currentlyAvailable = isCurrentlyAvailable(
        now,
        publishedAt,
        unPublishedAt,
      )

      return {
        id: pdfFile.id,
        contentKey: pdfFile.contentKey,
        coverKey: pdfFile.coverKey,
        issuerId: pdfFile.issuerId,
        issuer: pdfFile.issuer,
        ftpUser: pdfFile.ftpUser,
        publishedAt,
        unpublishedAt: unPublishedAt,
        createdAt: pdfFile.createdAt ? new Date(pdfFile.createdAt) : null,
        updatedAt: pdfFile.updatedAt ? new Date(pdfFile.updatedAt) : null,
        currentlyAvailable,
        order: pdfFile.order,
        optionalTitle: pdfFile.optionalTitle,
        coverUrl: pdfFile.coverUrl,
      }
    })
  }, [pdfFiles])

  async function showPdfFileClick(pdfId: number) {
    const response = await api.get<S3ResponseModel>(
      endpoints.getIssueUrl(pdfId),
    )

    const url = response.data.signedUrl
    window.open(url, '_blank')
  }

  // Modal
  const { isOpen, onOpen, onClose } = useDisclosure()
  const cancelRef = useRef(null)

  const coverUrl = ({ value }: { value: PDFTableData['coverUrl'] }) =>
    value ? <Image src={value} height="80px" /> : null

  const currentlyAvailable = ({
    value,
  }: {
    value: PDFTableData['currentlyAvailable']
  }) => (value ? <div>✅</div> : null)

  const actionsCell = ({ row }: { row: Row<PDFTableData> }) => {
    const { original: originalRow } = row

    return (
      <Flex justify="center">
        <Tooltip label="Vis PDF fil" aria-label="Vis pdf fil">
          <ViewIcon
            boxSize="5"
            marginRight="5"
            _hover={{ cursor: 'pointer' }}
            onClick={() => {
              showPdfFileClick(originalRow.id)
            }}
          />
        </Tooltip>
        <Tooltip label="Endre" aria-label="Endre utgiver">
          <EditIcon
            boxSize="5"
            marginRight="5"
            _hover={{ cursor: 'pointer' }}
            onClick={() => {
              setActionObject({
                action: 'update',
                pdfFile: originalRow,
              })
              onOpen()
            }}
          />
        </Tooltip>
        <Tooltip label="Slett" aria-label="Slett pdf fil">
          <DeleteIcon
            boxSize="5"
            _hover={{ cursor: 'pointer' }}
            onClick={() => {
              setActionObject({
                action: 'delete',
                pdfFile: originalRow,
              })
              onOpen()
            }}
          />
        </Tooltip>
      </Flex>
    )
  }

  const columns: Array<Column<PDFTableData>> = useMemo(
    () => [
      {
        Header: 'Forside',
        maxWidth: 80,
        accessor: 'coverUrl',
        Cell: coverUrl,
      },
      {
        Header: 'Vekting',
        accessor: 'order',
        maxWidth: 50,
        sortType: (data1, data2) => {
          const issuer1 = data1.original
          const issuer2 = data2.original

          if (issuer1.order > issuer2.order) {
            return 1
          } else if (issuer2.order > issuer1.order) {
            return -1
          } else {
            return (issuer1.optionalTitle || issuer1.issuer) >
              (issuer2.optionalTitle || issuer2.issuer)
              ? 1
              : -1
          }
        },
      },
      {
        Header: 'Utgiver',
        accessor: 'issuer',
        maxWidth: 100,
      },
      {
        Header: 'Alternativt navn',
        accessor: 'optionalTitle',
        maxWidth: 100,
      },
      {
        Header: 'Filnavn',
        accessor: (pdfFile) => {
          const fileNameMatchRegex = /^.+\/(.+)$/
          const fileNameMatchList = fileNameMatchRegex.exec(pdfFile.contentKey)

          return fileNameMatchList ? fileNameMatchList[1] : null
        },
      },
      {
        Header: 'Opprettet',
        accessor: 'createdAt',
        Cell: ({ value }: { value: PDFTableData['createdAt'] }) =>
          formatDate(value),
        maxWidth: 70,
        sortType: (data1, data2) =>
          dateSorter(data1.original.createdAt, data2.original.createdAt),
      },
      {
        Header: 'Tilgjengelig fra',
        accessor: 'publishedAt',
        Cell: ({ value }: { value: PDFTableData['publishedAt'] }) =>
          formatDate(value),
        maxWidth: 70,
        sortType: (data1, data2) =>
          dateSorter(data1.original.publishedAt, data2.original.publishedAt),
      },
      {
        Header: 'Tilgjengelig til',
        accessor: 'unpublishedAt',
        Cell: ({ value }: { value: PDFTableData['unpublishedAt'] }) =>
          formatDate(value),
        maxWidth: 70,
        sortType: (data1, data2) =>
          dateSorter(
            data1.original.unpublishedAt,
            data2.original.unpublishedAt,
          ),
      },
      {
        Header: 'Synlig i app',
        accessor: 'currentlyAvailable',
        Cell: currentlyAvailable,
        maxWidth: 70,
        sortType: (data1, data2) =>
          data1.original.currentlyAvailable &&
          !data2.original.currentlyAvailable
            ? 1
            : -1,
      },
      {
        id: 'actions',
        Header: <Text textAlign="center">Handlinger</Text>,
        accessor: 'id',
        maxWidth: 60,
        disableSortBy: true,
        Cell: actionsCell,
      },
    ],
    [],
  )

  return (
    <>
      <Table
        columns={columns}
        data={data}
        defaultSortBy={[{ id: 'order' }]}
        updatedRow={updatedRow}
      />
      {actionObject?.action === 'update' && (
        <PDFForm
          reloadPdfFiles={reload}
          isOpen={isOpen}
          issuersData={issuersData}
          onClose={onClose}
          mode="update"
          pdfFile={{
            ...actionObject.pdfFile,
          }}
          setUpdatedRow={setUpdatedRow}
        />
      )}
      {actionObject?.action === 'delete' && (
        <DeleteAlert
          isOpen={isOpen}
          onClose={onClose}
          cancelRef={cancelRef}
          header="Slett PDF fil"
          deletedObjectTitle={actionObject.pdfFile.contentKey}
          onClick={() => deletePdfFile(actionObject.pdfFile)}
        />
      )}
    </>
  )
}
