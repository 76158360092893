import React from 'react'
import { chakra, Box, useColorModeValue } from '@chakra-ui/react'

type TabSelectorInput<Header> = {
  headers: Header[]
  selectedTab: Header
  setSelectedTab: React.Dispatch<React.SetStateAction<Header>>
}

export function TabSelector<Header extends string>(
  props: TabSelectorInput<Header>,
) {
  const { headers, selectedTab, setSelectedTab } = props

  return (
    <Box>
      {headers.map((header) => (
        <chakra.button
          key={header}
          px="4"
          py="2"
          borderRadius="100"
          fontSize="17px"
          fontWeight="semibold"
          outline="none"
          color={useColorModeValue('brand.darkGrey', 'white')}
          bg={
            selectedTab === header
              ? useColorModeValue('brand.lightGreen20', 'gray.700')
              : 'transparent'
          }
          onClick={() => setSelectedTab(header)}
        >
          {header}
        </chakra.button>
      ))}
    </Box>
  )
}
