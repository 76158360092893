import React, { useEffect, useState, useMemo } from 'react'
import {
  Box,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Image,
  Text,
  Flex,
  Tooltip,
} from '@chakra-ui/react'
import { AudioTableData, AudioType, Track } from 'types/audio'
import { useAPI } from 'utils/useAPI'
import { endpoints } from 'utils/endpoints'
import { Column, Row } from 'react-table'
import { Table } from 'components/table'
import { EditIcon } from '@chakra-ui/icons'
import { EditAudioTrackForm } from 'components/EditAudioTrackForm'

type Props = {
  audio: AudioTableData
  isOpen: boolean
  onClose: () => void
}

export function TrackOverviewModal({ audio, isOpen, onClose }: Props) {
  const [tracks, setTracks] = useState<Track[]>()
  const [trackToEdit, setTrackToEdit] = useState<number | undefined>()
  const [updatedRow, setUpdatedRow] = useState<number | undefined>()
  const api = useAPI()
  const getAudioTracks = async () => {
    const response = await api.get<Track[]>(endpoints.audioTracksUrl(audio.id))
    setTracks(response.data)
  }

  const trackOverviewCell = ({ row }: { row: Row<Track> }) => (
    <Flex justify="center">
      <Tooltip label="Rediger" aria-label="Rediger lydbok">
        <EditIcon
          boxSize="5"
          _hover={{ cursor: 'pointer' }}
          onClick={() =>
            setTrackToEdit((s) =>
              s === row.original.trackNumber
                ? undefined
                : row.original.trackNumber,
            )
          }
        />
      </Tooltip>
    </Flex>
  )

  const columns: Array<Column<Track>> = useMemo(
    () => [
      {
        Header: audio.type === AudioType.AUDIOBOOK ? 'Kapittel' : 'Episode',
        maxWidth: 100,
        accessor: 'trackNumber',
      },
      {
        Header: 'Tittel',
        maxWidth: 100,
        accessor: 'title',
      },
      {
        Header: 'Beskrivelse',
        maxWidth: 100,
        accessor: 'description',
      },
      {
        id: 'actions',
        Header: <Text textAlign="center">Handlinger</Text>,
        maxWidth: 60,
        disableSortBy: true,
        Cell: trackOverviewCell,
      },
    ],
    [],
  )

  useEffect(() => {
    getAudioTracks()
  }, [audio.id])

  return (
    <Box>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent minWidth="900">
          <ModalHeader>
            {`${audio.title} - ${
              audio.type === AudioType.AUDIOBOOK ? 'Kapitler' : 'Episoder'
            }`}
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody borderWidth={1}>
            {audio.coverPhotoUrl && (
              <Image
                src={audio.coverPhotoUrl}
                height="200px"
                margin="0 auto"
                marginBottom="50"
              />
            )}
            {trackToEdit !== undefined && (
              <EditAudioTrackForm
                audioType={audio.type}
                audioId={audio.id}
                trackNumber={trackToEdit}
                onClose={() => setTrackToEdit(undefined)}
                reloadTracks={getAudioTracks}
                setUpdatedRow={(trackNumber: number) =>
                  setUpdatedRow(trackNumber)
                }
              />
            )}
            <Table
              columns={columns}
              data={tracks ?? []}
              defaultSortBy={[{ id: 'trackNumber' }]}
              updatedRow={updatedRow}
            />
          </ModalBody>
        </ModalContent>
      </Modal>
    </Box>
  )
}
