import { AddIcon } from '@chakra-ui/icons'
import { Center, Flex, Text, useColorModeValue } from '@chakra-ui/react'
import React from 'react'
import { DropzoneOptions, useDropzone } from 'react-dropzone'

type FileUploadInput = { id: string } & DropzoneOptions

export function FileInput(props: FileUploadInput) {
  const { id, ...dropzoneProps } = props

  const { getRootProps, getInputProps } = useDropzone({
    ...dropzoneProps,
  })

  return (
    <Flex
      {...getRootProps()}
      bg={useColorModeValue('#EDF2F7', '#FFFFFF14')}
      borderWidth="2px"
      borderRadius="lg"
      borderColor={useColorModeValue('gray.300', 'whiteAlpha.300')}
      borderStyle="dashed"
      _hover={{
        cursor: 'pointer',
        borderColor: useColorModeValue('gray.400', 'whiteAlpha.500'),
      }}
      justifyContent="center"
      alignItems="center"
      padding="2"
    >
      <input id={id} {...getInputProps()} />
      <Flex direction="column">
        <Center>
          <AddIcon boxSize="4" margin="2" />
        </Center>
        <Text>Velg fil, eller dra inn i boksen</Text>
      </Flex>
    </Flex>
  )
}
