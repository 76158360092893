import {
  Box,
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  useToast,
} from '@chakra-ui/react'
import React, { useCallback, useEffect, useState } from 'react'
import { FileInput, FileInputList } from 'components/file-input'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import { useAPI } from 'utils/useAPI'
import { IssuerResponse } from 'types/issuers'
import { endpoints } from 'utils/endpoints'
import { useMutation } from 'react-query'
import { DatetimePicker } from 'components/datetime-picker/DatetimePicker'
import { FileRejection } from 'react-dropzone'
import {
  PDFResponse,
  PDFTableData,
  PDFUpdateRequest,
  PDFUploadRequest,
} from 'types/pdf'
import { AxiosError, AxiosResponse } from 'axios'
import { format } from 'date-fns'
import { ProgressBar } from 'components/ProgressBar'

type Props = {
  isOpen: boolean
  issuersData: IssuerResponse[]
  reloadPdfFiles: () => void
  onClose: () => void
  setUpdatedRow: (id: number) => void
} & (PDFCreateInput | PDFUpdateInput)

type FormValues = {
  issuerId: number
  publishedAt?: string
  unpublishedAt?: string
  file?: File
  order: number
  optionalTitle: string | null
}

type PDFCreateInput = {
  mode: 'create'
  pdfFile?: undefined
}

type PDFUpdateInput = {
  mode: 'update'
  pdfFile: PDFTableData
}

export function PDFForm(props: Props) {
  const {
    isOpen,
    reloadPdfFiles,
    issuersData,
    onClose,
    mode,
    pdfFile,
    setUpdatedRow,
  } = props

  const {
    control,
    register,
    handleSubmit,
    setValue,
    getValues,
    watch,
    setError,
    clearErrors,
    reset,
    formState: { errors, isSubmitting },
  } = useForm<FormValues>()

  const [uploadProgress, setUploadProgress] = useState(0)

  const api = useAPI()

  const { mutate, isLoading } = useMutation<
    AxiosResponse<PDFResponse>,
    AxiosError<string>,
    PDFUploadRequest | PDFUpdateRequest
  >((inputData) => {
    if (mode === 'create') {
      return api.post(endpoints.pdfsUrl(), inputData, {
        headers: { 'Content-Type': 'multipart/form-data' },
        onUploadProgress: (progress) => {
          setUploadProgress(progress.loaded / (progress.total ?? 1))
        },
      })
    } else {
      return api.put(endpoints.editPdfsUrl(pdfFile!.id), inputData)
    }
  })

  const toast = useToast()

  function getRequest(data: FormValues): PDFUpdateRequest | PDFUploadRequest {
    if (mode === 'create') {
      const formData = new FormData()
      formData.append('issuerId', data.issuerId.toString())
      formData.append('file', data.file!)
      return formData
    } else {
      return {
        issuerId: data.issuerId,
        publishedAt: data.publishedAt
          ? new Date(data.publishedAt).toISOString()
          : null,
        unpublishedAt: data.unpublishedAt
          ? new Date(data.unpublishedAt).toISOString()
          : null,
        order: data.order,
        optionalTitle: data.optionalTitle,
      }
    }
  }

  function getToastTitle() {
    if (mode === 'create') {
      return 'lastet opp.'
    } else {
      return 'oppdatert'
    }
  }

  const resetFormAndClose = () => {
    setUploadProgress(0)

    reset({
      publishedAt: '',
      unpublishedAt: '',
      file: undefined,
      order: undefined,
      optionalTitle: undefined,
    })
    onClose()
  }

  const onSubmit: SubmitHandler<FormValues> = (data) => {
    const request = getRequest(data)

    mutate(request, {
      onSuccess: (response) => {
        setUpdatedRow(response.data.id)
        reloadPdfFiles()
        toast({
          title: `PDF ${getToastTitle()}`,
          status: 'success',
          duration: 6000,
          isClosable: true,
          position: 'bottom-right',
        })
        resetFormAndClose()
      },
      onError: () => {
        toast({
          title: `PDF ble ikke ${getToastTitle()}`,
          status: 'error',
          duration: 6000,
          isClosable: true,
          position: 'bottom-right',
        })
      },
    })
  }

  useEffect(() => {
    if (isOpen && pdfFile) {
      if (pdfFile.publishedAt) {
        setValue(
          'publishedAt',
          format(pdfFile.publishedAt, "yyyy-MM-dd'T'HH:mm"),
        )
      }
      if (pdfFile.unpublishedAt) {
        setValue(
          'unpublishedAt',
          format(pdfFile.unpublishedAt, "yyyy-MM-dd'T'HH:mm"),
        )
      }
      if (pdfFile.issuerId) {
        setValue('issuerId', pdfFile.issuerId)
      }
      if (pdfFile.order >= 0) {
        setValue('order', pdfFile.order)
      }
      if (pdfFile.optionalTitle) {
        setValue('optionalTitle', pdfFile.optionalTitle)
      }
    }
  }, [isOpen, pdfFile?.publishedAt, pdfFile?.unpublishedAt])

  const initialRef = React.useRef(null)
  const finalRef = React.useRef(null)

  const onDropCallback = useCallback(
    (acceptedFiles: File[], fileRejections: FileRejection[]) => {
      clearErrors('file')

      if (fileRejections.length > 0) {
        setError('file', {
          type: 'manual',
          message: 'Kun én fil kan lastes opp',
        })
      }

      setValue('file', acceptedFiles ? acceptedFiles[0] : undefined)
    },
    [getValues('file')],
  )

  return (
    <Box ref={finalRef}>
      <Modal
        initialFocusRef={initialRef}
        finalFocusRef={finalRef}
        isOpen={isOpen}
        onClose={resetFormAndClose}
        isCentered
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            {mode === 'create' ? 'Last opp fil' : 'Oppdater fil'}
          </ModalHeader>
          <ModalCloseButton />
          <form onSubmit={handleSubmit(onSubmit)}>
            <ModalBody pb={6}>
              <FormControl isRequired>
                <FormLabel htmlFor="issuer">Utgiver</FormLabel>
                <Select id="issuer" {...register('issuerId')}>
                  {issuersData
                    .sort((a, b) =>
                      a.title.toLowerCase() > b.title.toLowerCase() ? 1 : -1,
                    )
                    .map((issuer) => (
                      <option
                        key={`issuer-${issuer.id}`}
                        value={issuer.id}
                        selected={issuer.title === pdfFile?.issuer}
                      >
                        {issuer.title}
                      </option>
                    )) ?? []}
                </Select>
              </FormControl>
              {mode === 'update' ? (
                <>
                  <FormControl paddingTop="3">
                    <FormLabel htmlFor="publishedAt">
                      Tilgjengelig fra
                    </FormLabel>
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                      <Controller
                        control={control}
                        name="publishedAt"
                        render={({ field: { onChange, ref } }) => (
                          <DatetimePicker
                            value={watch('publishedAt')}
                            onChange={onChange}
                            ref={ref}
                          />
                        )}
                      />
                      <Button
                        style={{ marginLeft: 15 }}
                        onClick={() => {
                          const formatted = format(
                            new Date(),
                            "yyyy-MM-dd'T'HH:mm",
                          )
                          setValue('publishedAt', formatted)
                        }}
                      >
                        I dag
                      </Button>
                    </div>
                  </FormControl>
                  <FormControl paddingTop="3">
                    <FormLabel htmlFor="unpublishedAt">
                      Tilgjengelig til
                    </FormLabel>
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                      <Controller
                        control={control}
                        name="unpublishedAt"
                        render={({ field: { onChange, ref } }) => (
                          <DatetimePicker
                            value={watch('unpublishedAt')}
                            onChange={onChange}
                            ref={ref}
                          />
                        )}
                      />
                      <Button
                        style={{ marginLeft: 15 }}
                        onClick={() => {
                          const formatted = format(
                            new Date(),
                            "yyyy-MM-dd'T'HH:mm",
                          )

                          setValue('unpublishedAt', formatted)
                        }}
                      >
                        I dag
                      </Button>
                    </div>
                  </FormControl>
                  <FormControl
                    isInvalid={errors.order !== undefined}
                    marginTop="15px"
                    isRequired
                  >
                    <FormLabel htmlFor="order">Rekkefølge</FormLabel>
                    <Input
                      id="order"
                      type="number"
                      placeholder="Skriv inn her"
                      defaultValue={pdfFile?.order}
                      {...register('order', {
                        required: 'Dette feltet er påkrevd',
                        minLength: {
                          value: 1,
                          message: 'Minimumslengde er 1',
                        },
                      })}
                    />
                    <FormErrorMessage>
                      {errors.order && errors.order.message}
                    </FormErrorMessage>
                  </FormControl>
                  <FormControl marginTop="15px">
                    <FormLabel htmlFor="optionalTitle">
                      Alternativt tittel
                    </FormLabel>
                    <Input
                      id="optionalTitle"
                      type="text"
                      placeholder="Skriv inn her"
                      defaultValue={pdfFile?.optionalTitle || undefined}
                      {...register('optionalTitle')}
                    />
                  </FormControl>
                </>
              ) : (
                <FormControl
                  isRequired
                  isInvalid={errors.file !== undefined}
                  paddingTop="3"
                >
                  <FormLabel htmlFor="file">Fil</FormLabel>
                  <FileInput
                    id="file"
                    onDrop={onDropCallback}
                    maxFiles={1}
                    accept=".pdf"
                  />
                  <FileInputList
                    files={
                      watch('file')
                        ? ([getValues('file')] as File[])
                        : ([] as File[])
                    }
                    setFiles={(newFiles) => {
                      setValue('file', newFiles ? newFiles[0] : undefined)
                    }}
                  />
                  <FormErrorMessage>
                    {errors.file && errors.file.message}
                  </FormErrorMessage>
                </FormControl>
              )}
            </ModalBody>
            <ProgressBar progress={uploadProgress} isLoading={isLoading} />
            <ModalFooter>
              <Button
                variant="primary-button"
                mr={3}
                isLoading={isSubmitting || isLoading}
                loadingText="Laster opp"
                _hover={{
                  backgroundColor: 'teal',
                  color: 'white',
                }}
                type="submit"
              >
                {mode === 'update' ? 'Oppdater' : 'Last opp'}
              </Button>
              <Button onClick={resetFormAndClose}>Avbryt</Button>
            </ModalFooter>
          </form>
        </ModalContent>
      </Modal>
    </Box>
  )
}
