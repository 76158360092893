import React, { useState } from 'react'
import { Column, Row } from 'react-table'
import { Table } from 'components/table/Table'
import { endpoints } from 'utils/endpoints'
import { useMutation, useQueryClient } from 'react-query'
import { useAPI } from 'utils/useAPI'
import { DeleteIcon, EditIcon } from '@chakra-ui/icons'
import { Tooltip, useDisclosure, useToast, Flex, Text } from '@chakra-ui/react'
import { AxiosError, AxiosResponse } from 'axios'
import { DeleteAlert } from 'components/alerts/DeleteAlert'
import {
  IssuerDeleteRequest,
  IssuerResponse,
  IssuerTableData,
} from 'types/issuers'
import { IssuerForm } from 'components/IssuerForm'
import { formatDate } from 'utils/formatDate'

type Props = {
  issuersData: IssuerResponse[]
  reloadIssuers: () => void
  updatedRow?: number
  setUpdatedRow: (id: number) => void
}

export function IssuersTable({
  issuersData,
  reloadIssuers,
  updatedRow,
  setUpdatedRow,
}: Props) {
  const [actionObject, setActionObject] = useState<{
    action: 'update' | 'delete'
    issuer: IssuerTableData
  } | null>(null)

  const api = useAPI()

  const { mutate: _delete } = useMutation<
    AxiosResponse<unknown>,
    AxiosError<string>,
    IssuerDeleteRequest
  >((inputData) => api.delete(endpoints.issuerUrl(inputData.id)))

  const queryClient = useQueryClient()
  const toast = useToast()

  function deleteIssuer(issuerId: number) {
    _delete(
      { id: issuerId },
      {
        onSuccess: () => {
          queryClient.setQueryData<AxiosResponse<IssuerResponse[]> | undefined>(
            'issuers',
            (oldData: AxiosResponse<IssuerResponse[]> | undefined) => {
              if (oldData === undefined) return undefined

              return {
                ...oldData,
                data: oldData.data.filter((issuer) => issuer.id !== issuerId),
              }
            },
          )
          setActionObject(null)
          reloadIssuers()
          toast({
            title: 'Utgiver slettet.',
            status: 'success',
            duration: 3000,
            isClosable: true,
            position: 'bottom-right',
          })
        },
        onError: () => {
          toast({
            title: 'Utgiver ble ikke slettet.',
            status: 'error',
            duration: null,
            isClosable: true,
            position: 'bottom-right',
          })
        },
      },
    )
  }

  // Modal
  const { isOpen, onOpen, onClose } = useDisclosure()
  const cancelRef = React.useRef(null)

  const actionsCell = ({ row }: { row: Row<IssuerTableData> }) => {
    const { original: originalRow } = row

    return (
      <Flex justify="center">
        <Tooltip label="Endre" aria-label="Endre utgiver">
          <EditIcon
            boxSize="5"
            marginRight="5"
            _hover={{ cursor: 'pointer' }}
            onClick={() => {
              setActionObject({
                action: 'update',
                issuer: originalRow,
              })
              onOpen()
            }}
          />
        </Tooltip>
        <Tooltip label="Slett" aria-label="Slett utgiver">
          <DeleteIcon
            boxSize="5"
            _hover={{ cursor: 'pointer' }}
            onClick={() => {
              setActionObject({
                action: 'delete',
                issuer: originalRow,
              })
              onOpen()
            }}
          />
        </Tooltip>
      </Flex>
    )
  }

  const columns: Array<Column<IssuerTableData>> = React.useMemo(
    () => [
      {
        Header: 'Id',
        accessor: 'id',
        maxWidth: 20,
      },
      {
        Header: 'Navn',
        accessor: 'title',
      },
      {
        Header: 'FTP-bruker',
        accessor: 'ftpUser',
      },
      {
        Header: 'Standard vekting',
        accessor: 'defaultOrder',
        maxWidth: 50,
      },
      {
        Header: 'Automatisk publiser nye opplastinger',
        accessor: 'autoPublish',
        maxWidth: 60,
        Cell: ({ value }: { value: IssuerTableData['autoPublish'] }) =>
          value ? '✅' : '',
      },
      {
        Header: 'Opprettet',
        accessor: 'createdAt',
        Cell: ({ value }: { value: IssuerTableData['createdAt'] }) =>
          formatDate(value),
        maxWidth: 70,
      },
      {
        Header: 'Sist oppdatert',
        accessor: 'updatedAt',
        Cell: ({ value }: { value: IssuerTableData['updatedAt'] }) =>
          formatDate(value),
        maxWidth: 70,
      },
      {
        id: 'actions',
        Header: <Text textAlign="center">Handlinger</Text>,
        accessor: 'id',
        maxWidth: 50,
        disableSortBy: true,
        Cell: actionsCell,
      },
    ],
    [],
  )

  const data: IssuerTableData[] = React.useMemo(
    () =>
      issuersData.map((issuer) => ({
        id: issuer.id,
        title: issuer.title,
        category: issuer.category,
        ftpUser: issuer.ftpUser,
        createdAt: issuer.createdAt ? new Date(issuer.createdAt) : null,
        updatedAt: issuer.updatedAt ? new Date(issuer.updatedAt) : null,
        autoPublish: issuer.autoPublish,
        defaultOrder: issuer.defaultOrder,
        language: issuer.language,
      })) ?? [],
    [issuersData],
  )

  return (
    <>
      <Table
        columns={columns}
        data={data}
        defaultSortBy={[{ id: 'title' }]}
        updatedRow={updatedRow}
      />
      {actionObject?.action === 'update' && (
        <IssuerForm
          reloadIssuers={reloadIssuers}
          mode="update"
          isOpen={isOpen}
          onClose={onClose}
          issuer={{
            ...actionObject.issuer,
          }}
          setUpdatedRow={setUpdatedRow}
          defaultCategory={actionObject.issuer.category}
        />
      )}
      {actionObject?.action === 'delete' && (
        <DeleteAlert
          isOpen={isOpen}
          onClose={onClose}
          cancelRef={cancelRef}
          header="Slett utgiver"
          deletedObjectTitle={actionObject.issuer.title}
          onClick={() => deleteIssuer(actionObject.issuer.id)}
        />
      )}
    </>
  )
}
